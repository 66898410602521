@import "variables";

$font-path: "@kwsoft/styles/fonts/"; // override path to kw-styles fonts in node modules
@import "@kwsoft/styles/scss/kw-styles";
@import "@kwsoft/common-ng-form/src/scss/form";
@import "@kwsoft/common-ng-issue/src/scss/issue";
@import "@kwsoft/common-ng-auth/src/scss/auth";

@import "tables";
@import "accordions";

$font-size-login-title: ($font-size-base * 2);

html, body {
    height: 100%; // needed for sticky sidebar
}

wcr-dashboard-intro {

    h1 {
        margin-bottom: 3rem;
    }

    .row {
        margin-bottom: 1rem;

        .card {
            height: 100%;
            margin-top: 0;
        }
    }

}

.login-modal {
    .modal-content {
        background-image: url("assets/BNI_login.jpg") !important; // NOSONAR: used to override default background from auth module
    }

    kw-login-form .logo {
        margin-top: $spacer-md;
        margin-bottom: $spacer-md !important; // NOSONAR: used to override default logo from auth module
        background-image: none !important; // NOSONAR: used to override default logo from auth module
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: 'BNI WinCredit Bestand';
            font-size: $font-size-login-title;
            position: relative;
            color: $kw-brand-color;
            padding-bottom: $spacer-md;
        }
    }
}

.card-accordion.accordion > .accordion-item {
    @extend .card;
    padding: $spacer-lg;

    > .accordion-header {
        margin: 0;
        padding: 0;
    }

    .accordion-body {
        margin-top: $spacer-lg;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
}

#financing-accordion.accordion {
    .accordion-item:first-of-type {
        margin-top: $spacer-md !important;
    }
}

#pricing-history-accordion.accordion {
    .accordion-item:first-of-type {
        margin-top: 0 !important;
    }
}

#customer-overview-profile, #mortgage-details {
    .header-details {
        li {
            list-style: none;
            border-right: 1px solid $kw-gray-lighter;
            padding: 0 $spacer-md;
        }

        li:first-child{
            padding: 0 $spacer-md 0 0;
        }

        li:last-child{
            border-right: 0;
        }
    }
}

#header-top {
    .navbar-right {
        .btn.user-menu {
            width: $spacer-lg;
            height: $spacer-lg;
            padding: 0;
            text-transform: capitalize;
            border-radius: 100%;
            color: $white;
            background-color: $primary;
            font-weight: $font-weight-semibold;
        }

        .dropdown-item.user-name {
            &:active, &:focus, &:hover {
                color: inherit;
                background-color: inherit;
            }
        }
    }

    .search-wrapper {
        position: relative;

        .fa-solid {
            position: absolute;
            left: 9px;
            z-index: 1000;
            top: 9px;
            color: $kw-gray-lighter;
        }

        ng-select {
            margin-right: $spacer-md;
            width: 400px;

            .ng-input, .ng-select-container {
                padding-left: $spacer-lg !important;
            }

            .ng-arrow-wrapper {
                display: none !important;
            }

            ng-dropdown-panel {
                margin-top: 0 !important;

                .ng-option div {
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }

        ng-select.initState ng-dropdown-panel {
            display: none !important;
        }

        ng-select.ng-select-focused > .ng-select-container {
            border-color: #006EEF !important;
            border-radius: $border-radius !important;
        }
    }
}

wcr-left-navigation {
    .nav {
        background-color: $kw-navbar-bg;
        height: 100vh;
        width: 14vw;
        min-width: 11.5rem;
        max-width: 13.5rem;

        li a {
            color: $kw-gray-lighter;
            &:hover {
                background-color: $kw-navbar-open-bg;
                color: $kw-gray-light;
            }
            &.active {
                color: white;
            }
        }

    }
}

.progress {
background-color: $kw-gray;

    .progress-bar {
        background-color: #D9D9D9;
    }

    &.danger .progress-bar {
        background-color: $danger;
    }
}

// Badge styling

.badge-success {
    background-color: $kw-success-bg;
    color: $body-color;
}

.badge-warning {
    background-color: $kw-warning-bg;
    color: $body-color;
}

.badge-danger {
    background-color: $kw-danger-bg;
    color: $body-color;
}

.badge-gray {
    background-color: $kw-gray-lighter;
    color: $body-color;
}

.kw-bg-lightest {
    background-color: $kw-gray-lightest;
}

.no-padding-popover .popover-body {
    padding: 0 .25rem 0 .25rem !important;
    .vr {
        background-color: $kw-gray-lighter;
        opacity: unset;
    }
}
